import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth,db } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";



const AdminLogin = () => {

  
    const navigate= useNavigate()
   
    const [email, setEmail]= useState("")
    const [error, setError]= useState(false)
    const [password, setPassword]= useState("")

    

   async function loginSubmit(e){
        e.preventDefault();

        try {
            
            signInWithEmailAndPassword(auth, email, password).then(()=>{
                navigate("/admin")
            })
          
         
           
           
        } catch (error) {
            setError(true)

            
        }
        
        


    }

    
  return (
    <div className='row bg-admin bg h-100vh align-items-center justify-content-center'>
        <div className="col-12 position-absolute filter h-100 align-items-center justify-content-center row ">
        

        </div>
        <div  className={error? "error-input col-xl-4  col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto": "" +"col-xl-4  col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto"}>
            <div className="card card-plain">
                <div className="card-header pb-0 text-start">
                    <h4 className="font-weight-bolder">Sign In</h4>
                    <p className="mb-0">Enter your email and password to sign in</p>
                </div>
                <div className="card-body">
                    <form onSubmit={loginSubmit} role="form">
                        <div className="mb-3">
                        <input type="email" value={email} onInput={(e)=>{setEmail(e.target.value)}} className="form-control form-control-lg" placeholder="Email" aria-label="Email"/>
                        </div>
                        <div className="mb-3">
                        <input type="password" value={password} onInput={(e)=>{setPassword(e.target.value)}} className="form-control form-control-lg" placeholder="Password" aria-label="Password"/>
                        </div>
                        
                        <div className="text-center">
                        <button type="submit" className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">Sign in</button>
                        </div>
                    </form>
                </div>
               
            </div>
        </div>


       
    </div>
  )
}

export default AdminLogin