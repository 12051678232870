import React from 'react'

import Accordion from 'react-bootstrap/Accordion';
import Brand from './Brand';
import HomeSection from './HomeSection';
import MobileSection from './MobileSection';
import ServiceSection from './ServiceSection';
import StatsSection from './StatsSection';

const Dashboard = () => {
  
  return (
    <div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item  eventKey="0">
            <Accordion.Header>Brand Details</Accordion.Header>
            <Accordion.Body>
              <Brand />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item  eventKey="1">
            <Accordion.Header>Home Section</Accordion.Header>
            <Accordion.Body>
              <HomeSection />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item  eventKey="2">
            <Accordion.Header>Stats</Accordion.Header>
            <Accordion.Body>
              <StatsSection />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item  eventKey="3">
            <Accordion.Header>Services</Accordion.Header>
            <Accordion.Body>
              <ServiceSection />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item  eventKey="4">
            <Accordion.Header>Mobile</Accordion.Header>
            <Accordion.Body>
              <MobileSection />
            </Accordion.Body>
          </Accordion.Item>
  
        </Accordion>
    </div>
  )
}

export default Dashboard