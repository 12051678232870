import React from 'react'
import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import useAuth from '../Auth'
import { IconPickerItem } from 'react-fa-icon-picker'

import { faLongArrowRight} from '@fortawesome/free-solid-svg-icons'
import {db} from '../firebase'
import {collection, addDoc, Timestamp} from 'firebase/firestore'


const Footer = () => {
    const [email, setEmail]= useState("")
    const [message, setMessage]= useState("")
    const [name, setName]= useState("")
    const {brand}= useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
       
        try {
          await addDoc(collection(db, 'messages'), {
            name: name,
            message: message,
            email: email,
            created: Timestamp.now()
          })
         
        } catch (err) {
          alert(err)
        }
      }
  return (
    <div className='footer py-3 pt-4'>
        <div className="row col-11 mx-auto align-items-center">
            
            <div className="col-sm-7  px-0-rs">
                <div className="col-12 px-0-rs">
                    <img className='white-img'  src={brand.logo} alt="Logo White" />
                    <p className='w-75 ms-2 text-white'>{brand.description}</p>

                </div>
                <div className=" socials row mx-0 justify-content-start">
                {
                  brand.socials.map((a,index)=>{
                    return <a className="w-max-content" key={index} target="_blank" href={a.link}><IconPickerItem  icon={a.icon} size={28} color="#FFF"  /></a> 
                  })
                }

                

                </div>
               

            </div>
            <div className="col-sm-5 contact">
                <h4 className='text-white row'>Get In Touch</h4>
             
                <form className="row flex-wrap justify-content-between " onSubmit={handleSubmit} action="">
                    <input className='w-45' placeholder='Name' value={name} onInput={(e)=>{setName(e.target.value) }} type="text" name="" id="" />
                    <input className='col-sm-6' placeholder='Email' value={email} onInput={(e)=>{setEmail(e.target.value)}} type="email" name="" id="" />
                    <textarea className='col-11 mt-2' placeholder='Write us a message!' value={message} onInput={(e)=>{setMessage(e.target.value)}} />
                    <button className='col-1 mt-2 border-white-rs ' type='submit'><FontAwesomeIcon color='white'   icon={faLongArrowRight}/></button>

                </form>
            </div>

            <div className="col-12 mt-3 text-center">
                <p className='text-white mb-0'>Copyright © 2022 Alkebulan </p>

            </div>
           
            
        </div>
    </div>
  )
}

export default Footer

