import React from 'react'
import Button from 'react-bootstrap/Button';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPicker } from 'react-fa-icon-picker'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import {db,storage,image_link} from '../firebase'
import { ref, getDownloadURL, uploadBytesResumable,deleteObject,listAll } from "firebase/storage";
import {collection, setDoc,doc, query, orderBy, onSnapshot, Timestamp} from 'firebase/firestore'
const StatsSection = () => {
  const docRef = doc(db, "Sections", "stats" );
  const [stats, setStats]= useState([{
    title: "Vendors",
    image: "",
    count: ""


  },
  {
    title: "Suppliers",
    image: "",
    count: ""


  },
  {
    title: "Employees",
    image: "",
    count: ""


  },{
    title: "Customers",
    image: "",
    count: ""


  }
])

  useEffect(() => {
   

    const q = query(collection(db,"Sections"))
    onSnapshot(q, (querySnapshot) => {
     
        setStats(querySnapshot.docs[3].data().data)
    })
  },[])

  

    
    const handleAccompChange = (index, name,value) => {
     
        let data = [...stats];
        data[index][name] =value;
        setStats(data);
    }
    const handleStatsImgChange = (i, event) => {
        const storageRef = ref(storage, `images/stats/${stats[i].title}`);
        const listRef = ref(storage, 'images/stats');
  
        const uploadTask = uploadBytesResumable(storageRef, event.target.files[0]);
  
        uploadTask.on("state_changed",
            (snapshot) => {
            const progress =   Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress)
  
            },
            (error) => {
            console.log(error);
            },
            () => {
              let data = [...stats];
              data[i]["image"] = image_link+ "stats%2F" + stats[i].title+ "?alt=media" ;
          
              setStats(data);
              
  
  
            }
        );
       
        
        
    }

    const handleStatsSubmit = async (e) => {
    e.preventDefault()
    
    try {

        await setDoc(docRef, {
            data: stats
  
          })
        
        
        
    
    } catch (err) {
        alert(err)
    }
    }
  return (
    <div>
        <Form onSubmit={handleStatsSubmit}>
       

            
          
            {stats.map((item, index) => {
              return (
                <div className='d-flex spec-flex flex-wrap align-items-center' key={index}>
                  <Form.Label className='col-12'>{item.title}</Form.Label>

                  <Form.Group className="mb-3 mw-100px ">
                    <img className='w-100 mx-auto ' src={item.image} alt="" />
                    <Form.Label className='btn btn-primary w-100 ' htmlFor={"vendimage"+index}>Image</Form.Label>
                    <Form.Control type="file" name='image' id={"vendimage"+index}  className='d-none' onChange={event => handleStatsImgChange(index, event)} placeholder="logoUpload" />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Count</Form.Label>
                    <Form.Control type='number' name='count' value={item.count} onChange={event => handleAccompChange(index, "count", event.target.value)} placeholder="Description" />
                  </Form.Group>

                 
                </div>
              )
            })}

           
          
            <Button className='w-100' variant="primary" type="submit">
              Submit
            </Button>
          </Form>
    </div>
  )
}

export default StatsSection