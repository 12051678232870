import React from 'react'
import useAuth from '../Auth'


const WhoWeAre = () => {
  const {brand}= useAuth()

  return (
    <div id='about' className='sections who-we-are mb-5 '>
        <div className="col-9 mx-auto d-flex align-items-center justify-content-center">
            <div className="col-sm-5">
                <h1 >Who We are</h1>
            </div>
            <div className="col-sm-7 px-4">
                <p>{brand.about}</p>
                <a className='moreButton d-block mt-4' href="#services">Learn More</a>
            </div>
        </div>
 

        <h2 className='text-10 sideKem'>KE-MASSA</h2>
    </div>
  )
}

export default WhoWeAre