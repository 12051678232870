import React, {useEffect} from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faUsers } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import useAuth from '../Auth'


const AdminLayout = () => {
    const {logOut, brand} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        let user = sessionStorage.getItem('user')
        
        if (user=="null") {
            navigate('/login')
        }
    }, [])
    const logout =async ()=>{
        
        
        
        try {
            await logOut()
            navigate('/')
             
         } catch (error) {
            console.log(error)
            
 
             
         }
    }
  return (
    <div className='row mx-0 admin pt-3'>
        <div className="col-sm-12 my-2 px-3 row mx-0 align-items-center justify-content-between">
            <img className='logoAdmin' src={brand.logo} alt="" />

            <button className='outline-btn' onClick={logout}>Logout</button>
        </div>
        <div className="col-sm-2">
            <NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to="" end><FontAwesomeIcon icon={faHouse} /> Dashboard</NavLink>
            <NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to="messages" ><FontAwesomeIcon icon={faUsers} /> Messages</NavLink>
          





        </div>
        <div className="col-sm-10 px-3">
            <Outlet />
            

        </div>
         
    </div>
  )
}

export default AdminLayout