import React, { useState,useEffect } from 'react'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import $ from 'jquery'; 
import logo from '../assets/img/logo.png'
import useAuth from '../Auth'

const CustNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
   
    const {brand}= useAuth()
    const removeShow = () => {     
        $("#basic-navbar-nav").removeClass("show")  
    };

        
$(function () {
  $( ".panel" ).hover(
    function() {
       $( ".panel.active" ).removeClass("active")
      $( this ).addClass("active");
    }, function() {
      $( this ).find( "span" ).last().remove();
    }
  );
        $(document).scroll(function () {
          var $nav = $(".navbar");
          $nav.toggleClass('scrolled', $(window).scrollTop() > $(".hero-section").height()-300);
          $(".accomplishments").toggleClass('d-none', $(window).scrollTop() > $(".hero-section").height()-300);

        });
      });
  return (
   
    <Navbar className="navbar px-5 py-3 navbar-expand-lg justify-content-between"  expand="lg">
    
        <Navbar.Brand href="#"> <img src={brand.logo } alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse  id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={removeShow}  href="#about"><h4>About Us</h4></Nav.Link>
            <Nav.Link onClick={removeShow} href="#services"><h4>Services</h4></Nav.Link>
            <Nav.Link onClick={removeShow} href="#getapp"><h4>Vendors</h4></Nav.Link>
            <Nav.Link onClick={removeShow} href="#getapp"><h4>Suppliers</h4></Nav.Link>
            
           
          </Nav>
        </Navbar.Collapse>
     
    </Navbar>
    
  )
}

export default CustNavbar