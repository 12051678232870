import React from 'react'
import {useState, useEffect} from 'react'
import {collection, query, orderBy, onSnapshot} from "firebase/firestore"
import {db} from '../firebase'

const Messages = () => {
    const [messages, setMessages] = useState([])
    useEffect(() => {
        const q = query(collection(db, 'messages'), orderBy('created', 'desc'))
        onSnapshot(q, (querySnapshot) => {
        setMessages(querySnapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })))
        })
      },[])
  return (
    <div>
        <div class="col-11 mx-auto">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="card-title">Contact Messages</h3>
                                <p class="card-subtitle">Messages from customers</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table messages table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {messages.map((message,index)=>{
                                           return <tr key={index}>
                                                <th scope="row">{index+1}</th>
                                                <td>{message.data.name}</td>
                                                <td>{message.data.email}</td>
                                                <td>{message.data.message}</td>
                                            </tr>
                                        })}
                                        
                                 
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
    </div>
  )
}

export default Messages