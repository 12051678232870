import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPickerItem } from 'react-fa-icon-picker'
import {collection, query, onSnapshot} from 'firebase/firestore'
import {db} from '../firebase'
import delivery from '../assets/img/services.png'

import { useEffect,useState } from 'react';

const Services = () => {
    const [services, setServices]= useState({
        title: "",
        description: "",
        services: []
    })
   useEffect(() => {
   

      const q = query(collection(db,"Sections"))
      onSnapshot(q, (querySnapshot) => {
       
          setServices(querySnapshot.docs[2].data())
         
       
      })
    },[])
  return (
    <div id='services' className='sections py-5'>
        <div className="col-11 mx-auto row align-items-center">
            <div className="col-sm-7">
                <h1 className='display-1 kemassa-green'>{services.title}</h1>
                <p className='w-75'>{services.description}</p>

                <div className="row mt-5">

                    {
                        services.services.map((a,index)=>{
                            return   <div key={index} className="col-6">
                                <IconPickerItem icon={a.icon} size={40} color="#9A4403"  />
                      
                                <h4 className='kemassa-green mb-1 mt-2'>{a.title}</h4>
                                <p>{a.description}</p>
        
        
                            </div>
                        })
                    }
                  
  
                </div>
            </div>
            <div className="col-sm-5 ">
                <img className='servicesImg' src={delivery} alt="" />



            </div>

        </div>
    </div>
  )
}

export default Services