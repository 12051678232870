import React from 'react'
import { useRef,useState, useEffect } from 'react';
import bg from '../assets/img/bg.png'
import grass from '../assets/img/grass.png'

import { IconPickerItem } from 'react-fa-icon-picker'
import useAuth from '../Auth'


import {collection, query, orderBy, onSnapshot} from "firebase/firestore"
import {db} from '../firebase'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Hero = () => {
  const [hero, setHero] = useState({
    title: "",
    description: "",
    accomplishments: []
  });
  const {brand}= useAuth()

  
  useEffect(() => {
   

  const q = query(collection(db,"Sections" ))
  onSnapshot(q, (querySnapshot) => {
        setHero(querySnapshot.docs[0].data())
    })

   


  },[])
 
 
  gsap.registerPlugin(ScrollTrigger);

  
  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".hero-section",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    })
    
      .to(".grass , .blur ", { y: -150 , ease: "power2.out" })
     


      

  });
  return (
    <div className=' hero-section position-relative '>
      <img className='bgField position-relative' src={bg} alt="" />
      <img className='grass' src={grass} alt="" />
      <div  className="heroText d-flex align-items-center justify-content-center text-center text-white">
        <div className="col-sm-6">
          <h2 className='text-10'>{hero.title}</h2>
          <p className='w-50 mx-auto fs-larger'>{hero.description} </p>
          <div className="d-block-rs accomp-rs">

            {
              hero.accomplishments.map((accomp,index)=>{

                return <div key={index} className="me-4 col-4 d-flex flex-nowrap align-items-center text-white">
                <img src={accomp.image} alt="" />
                <p>{accomp.description}</p>
              </div>
              })
            }
            
            
          </div>
        </div>
      
      </div>
      <div className="d-flex accomplishments w-100">
        <div className="col-7  d-flex">
        {
              hero.accomplishments.map((accomp,index)=>{

                return <div key={index} className="me-4 col-4 d-flex flex-nowrap align-items-center text-white">
                <img src={accomp.image} alt="" />
                <p>{accomp.description}</p>
              </div>
              })
            }
    
        
          


        </div>
        <div className="col-5 d-flex align-items-center justify-content-end">
        
        {
          brand.socials.map((a,index)=>{
            return <a key={index} target="_blank" className='d-block mx-3' href={a.link}><IconPickerItem  icon={a.icon} size={35} color="#FFF"  /></a> 
          })
        }
          



        </div>



      </div>
      <div className='blur'></div>
    </div>

  )
}

export default Hero