import React from 'react'
import styled from 'styled-components'

import $ from 'jquery'
import {db} from '../firebase'
import {collection, query, onSnapshot} from 'firebase/firestore'

import { useEffect,useState } from 'react';

import { gsap } from "gsap";


const Stat= styled.div`

   &:before{
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-image: url(${props=>props.bg});
      background-position: center;
      filter: brightness(0.7);
      border-radius: 5px;
   }

   
   min-height: 70vh;
   display: flex;
   position: relative;
   
   flex-direction: column;
   align-items: center;
   position: relative;
   border-radius: 5px;
   margin-right: 15px;
    
   justify-content: center;
`

const Stats = () => {
   const [stats, setStats]= useState([])
   useEffect(() => {
   

      const q = query(collection(db,"Sections"))
      onSnapshot(q, (querySnapshot) => {
       
          setStats(querySnapshot.docs[3].data().data)
      })
    },[])
   



 



  return (
    <div className='sections  stats my-3 px-2 '>
       <div className="col-11 mx-auto">
        <div className="d-flex align-items-center flex-nowrap">
        
          {stats.map((item, index)=>{
            return <Stat key={index} className={index==0? "active col-sm-2 panel" : ''+ ' col-sm-2 panel'} bg={item.image}>
                     <h1>{item.count}</h1>
                  <h3>{item.title}</h3>
                  </Stat>
          })}
            
  
            <div className="col-sm-1">
               <h1 className='display-1 rotStats'>KE-MASSA</h1>

        </div>
            
               
            

        </div>
       </div>
    </div>
  )
}

export default Stats