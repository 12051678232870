import React from 'react'
import Button from 'react-bootstrap/Button';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faClose} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import {db,storage,image_link} from '../firebase'
import { ref, getDownloadURL, uploadBytesResumable,deleteObject,listAll } from "firebase/storage";
import {collection, setDoc,doc, query, orderBy, onSnapshot, Timestamp} from 'firebase/firestore'
const HomeSection = () => {
  const docRef = doc(db, "Sections", "home" );
  const [title, setTitle]= useState("")
  const [description, setDescription]= useState("")
  const [fileUrls, setFileUrls]= useState(new Array())
  const [accomplishments, setAccomplishments] = useState([
      {id:uuidv4(), image: '', description: '' }
  ])

  useEffect(() => {
   

    const q = query(collection(db,"Sections" ))
    onSnapshot(q, (querySnapshot) => {
        setTitle(querySnapshot.docs[0].data().title)
        setDescription(querySnapshot.docs[0].data().description)
        setAccomplishments(querySnapshot.docs[0].data().accomplishments)
    })
  },[])

  

    
    const handleAccompChange = (index, event) => {
        console.log(index)
        let data = [...accomplishments];
        data[index][event.target.name] = event.target.name=="image" ? image_link+ "accomplishment%2FaccompImage" + accomplishments[index].id+ "?alt=media" :  event.target.value;
        setAccomplishments(data);
    }
    const handleImgAccompChange = (i, event) => {
      const storageRef = ref(storage, `images/accomplishment/accompImage${accomplishments[i].id}`);
      const listRef = ref(storage, 'images/accomplishment');

      const uploadTask = uploadBytesResumable(storageRef, event.target.files[0]);

      uploadTask.on("state_changed",
          (snapshot) => {
          const progress =   Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(progress)

          },
          (error) => {
          console.log(error);
          },
          () => {
            let data = [...accomplishments];
            data[i]["image"] = image_link+ "accomplishment%2FaccompImage" + accomplishments[i].id+ "?alt=media" ;
            console.log(data[i]["image"])
            setAccomplishments(data);
            


          }
      );
     
      
      
  }
    const addFields = () => {
    let newfield = { id:uuidv4(), image: '', description: '' }

    setAccomplishments([...accomplishments, newfield])
    }
    const removeItem = (index,event)=>{
      const storageRef = ref(storage, `images/accomplishment/accompImage${accomplishments[index].id}`);      
      deleteObject(storageRef).then(() => {
        setAccomplishments(accomplishments.splice(index,1))
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
      accomplishments.splice(index,1)
      setAccomplishments([...accomplishments])

     

    }

    const handleHomeSubmit = async (e) => {
    e.preventDefault()
    
    try {

      
        
   
     

        
        await setDoc(docRef, {
          title: title,
          description: description,
          accomplishments: accomplishments.map((a,i)=>{
            console.log({ image: a.image , description: a.description })
            return { image: a.image , description: a.description }})

        })
      
        
        
        
    
    } catch (err) {
        alert(err)
    }
    }
  return (
    <div>
        <Form onSubmit={handleHomeSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" value={title} onInput={(e)=>{setTitle(e.target.value) }} placeholder="Enter Title" />
              
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" value={description} onInput={(e)=>{setDescription(e.target.value) }} placeholder="Description" />
            </Form.Group>
            <div className="row mx-0 align-items-center">
              <h5 className='w-50'>Accomplishments</h5>
              <button className='w-50 addButt' type='button' onClick={addFields}>Add More</button>
            </div>
            {accomplishments.map((input, index) => {
              return (
                <div className='d-flex spec-flex align-items-center' key={index}>
                  
                  <Form.Group className="mb-3 mw-100px ">
                    <img className='w-100 mx-auto ' src={input.image} alt="" />
                    <Form.Label className='btn btn-primary w-100 ' htmlFor={"image"+index}>Image</Form.Label>
                    <Form.Control type="file" name='image' id={"image"+index}  className='d-none'  onChange={event => handleImgAccompChange(index, event)} placeholder="Description" />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" name='description' value={input.description} onChange={event => handleAccompChange(index, event)} placeholder="Description" />
                  </Form.Group>

                  <button type='button' className='removeButt' onClick={event => removeItem(index, event)} ><FontAwesomeIcon icon={faClose} /></button>
                 
                </div>
              )
            })}

           
          
            <Button className='w-100' variant="primary" type="submit">
              Submit
            </Button>
          </Form>
    </div>
  )
}

export default HomeSection