import React from 'react'
import {db} from '../firebase'
import {collection, query, onSnapshot} from 'firebase/firestore'

import { useEffect,useState } from 'react';
const Mobile = () => {

  const [mobsec, setMobsec]= useState({
    title: "",
    description: "",
    app: "",
    image: ""
  })
  useEffect(() => {
   

    const q = query(collection(db,"Sections" ))
    onSnapshot(q, (querySnapshot) => {
        setMobsec(querySnapshot.docs[1].data())
        

    })
  },[])
  return (
    <div id='getapp' className='sections mobile-sec'>

        <div className="row h-100 align-items-center  col-11 mx-auto mr-0">
            <div className="col-4">
                <h2 className='display-3'>{mobsec.title}</h2>
                <p>{mobsec.description}</p>
                <a id='mobButton' href={mobsec.app} className='moreButton bg-white'>Download App!</a>
            </div>
            <div className="col-8">
                <img className=" d-block" src={mobsec.image} alt="" />
            </div>

        </div>
        


    </div>
  )
}

export default Mobile