import Hero from "./sections/Hero";
import CustNavbar from "./sections/Navbar";
import Stats from "./sections/Stats";
import WhoWeAre from "./sections/WhoWeAre";
import Services from "./sections/Services";
import Footer from "./sections/Footer";
import Mobile from "./sections/Mobile";
import Contact from "./sections/Contact";

function Home() {
  return (
    <div className="App">
         <CustNavbar/>
        <Hero />
        <WhoWeAre />
        
        
        
        <Stats />
        <Services/>
        <Mobile />
        {/* <Contact /> */}
        <Footer />

       
     
    </div>
  );
}

export default Home;
