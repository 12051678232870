import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import firebaseApp from "./firebase";
import { auth,db } from "./firebase";
import {collection, query, orderBy, onSnapshot} from "firebase/firestore"


export const AuthContext = React.createContext();
const useAuth= ()=>{
  return useContext(AuthContext)
}
export default useAuth

export const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [brand, setBrand]= useState({
    logo: "",
    description : "",
    about: "",
    socials: new Array()
})



  const logOut =()=>{
    return signOut(auth)
  }
  useEffect(() => {
    const q = query(collection(db,"Brand" ))
      onSnapshot(q, (querySnapshot) => {
            setBrand(querySnapshot.docs[0].data())
        })
    
    const unsubscribe= auth.onAuthStateChanged((user) => {
      
      setCurrentUser(user)
      sessionStorage.setItem('user', JSON.stringify(user));
      setPending(false)
    });
    return unsubscribe
  }, []);

 

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        logOut,brand
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};