import React from 'react'
import Button from 'react-bootstrap/Button';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPicker } from 'react-fa-icon-picker'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import {db,storage,image_link} from '../firebase'
import { ref, getDownloadURL, uploadBytesResumable,deleteObject,listAll } from "firebase/storage";
import {collection, setDoc,doc, query, orderBy, onSnapshot, Timestamp} from 'firebase/firestore'
const Brand = () => {
  const docRef = doc(db, "Brand", "kemassa" );
  const [logo, setLogo]= useState("")
  const [description, setDescription]= useState("")
  const [about, setAbout]= useState("")
  const [socials, setSocials] = useState([
      {id:uuidv4(), icon: '', link: '' }
  ])

  useEffect(() => {
   

    const q = query(collection(db,"Brand" ))
    onSnapshot(q, (querySnapshot) => {
        setDescription(querySnapshot.docs[0].data().description)
        setLogo(querySnapshot.docs[0].data().logo)
        setAbout(querySnapshot.docs[0].data().about)

        setSocials(querySnapshot.docs[0].data().socials)
    })
  },[])

  

    
    const handleAccompChange = (index, name,value) => {
     
        let data = [...socials];
        data[index][name] =value;
        setSocials(data);
    }
    const handleLogoUpload = ( event) => {
      const storageRef = ref(storage, `images/logo`);


      const uploadTask = uploadBytesResumable(storageRef, event.target.files[0]);

      uploadTask.on("state_changed",
          (snapshot) => {
          const progress =   Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(progress)

          },
          (error) => {
          console.log(error);
          },
          () => {
            
      
           
            setLogo(image_link+"logo?alt=media" );
            


          }
      );
     
      
      
  }
    const addFields = () => {
    let newfield = { id:uuidv4(), icon: '', link: '' }

    setSocials([...socials, newfield])
    }
    const removeItem = (index,event)=>{
      
      socials.splice(index,1)
      setSocials([...socials])

     

    }

    const handleBrandSubmit = async (e) => {
    e.preventDefault()
    
    try {

        await setDoc(docRef, {
            logo: logo,
            description: description,
            about: about,
            socials: socials.map((a,i)=>{
              return { id: a.id, icon: a.icon, link: a.link }})
  
          })
        
        
        
    
    } catch (err) {
        alert(err)
    }
    }
  return (
    <div>
        <Form onSubmit={handleBrandSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Group className="mb-3 mw-100px ">
                    <img className='w-100 mx-auto ' src={logo} alt="" />
                    <Form.Label className='btn btn-primary w-100 ' htmlFor={"image"}>Image</Form.Label>
                    <Form.Control type="file" name='image' id={"image"}  className='d-none'  onChange={handleLogoUpload} placeholder="logoUpload" />
                  </Form.Group>
              
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDesc">
              
              <Form.Group className="mb-3  ">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" name='description' value={description}   onChange={(event)=>setDescription(event.target.value)} placeholder="A little Description" />
            </Form.Group>
              
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicAbout">
              
              <Form.Group className="mb-3  ">
                <Form.Label>About</Form.Label>
                <Form.Control as={"textarea"} name='about' value={about}   onChange={(event)=>setAbout(event.target.value)} placeholder="A little Description" />
            </Form.Group>
              
            </Form.Group>

            
            <div className="row mx-0 align-items-center">
              <h5 className='w-50'>Socials</h5>
              <button className='w-50 addButt' type='button' onClick={addFields}>Add More</button>
            </div>
            {socials.map((input, index) => {
              return (
                <div className='d-flex spec-flex align-items-center' key={index}>
                  
                  <Form.Group className="mb-3  ">
                    <IconPicker name="icon" value={input.icon} onChange={(v) => handleAccompChange(index, "icon",v)}  />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Link</Form.Label>
                    <Form.Control type='text' name='link' value={input.link} onChange={event => handleAccompChange(index, "link", event.target.value)} placeholder="Description" />
                  </Form.Group>

                  <button type='button' className='removeButt' onClick={event => removeItem(index, event)} ><FontAwesomeIcon icon={faClose} /></button>
                 
                </div>
              )
            })}

           
          
            <Button className='w-100' variant="primary" type="submit">
              Submit
            </Button>
          </Form>
    </div>
  )
}

export default Brand