import React from 'react'
import Button from 'react-bootstrap/Button';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePay } from '@fortawesome/free-brands-svg-icons'
import Form from 'react-bootstrap/Form';
import {db,storage,image_link} from '../firebase'
import { ref, getDownloadURL, uploadBytesResumable,deleteObject,listAll } from "firebase/storage";

import {collection, setDoc,doc, query, orderBy, onSnapshot, Timestamp} from 'firebase/firestore'
const MobileSection = () => {
  const docRef = doc(db, "Sections", "mobile" );
  const [title, setTitle]= useState("")
  const [description, setDescription]= useState("")
  const [image, setImage]= useState("")
  const [app, setApp]= useState("")



 

  useEffect(() => {
   

    const q = query(collection(db,"Sections" ))
    onSnapshot(q, (querySnapshot) => {
        setTitle(querySnapshot.docs[1].data().title)
        setDescription(querySnapshot.docs[1].data().description)
        setImage(querySnapshot.docs[1].data().image)
        setApp(querySnapshot.docs[1].data().app)

    })
  },[])

  const handleFileUpload = ( event,file) => {
    const storageRef = ref(storage, `images/mobile/${file}`);


    const uploadTask = uploadBytesResumable(storageRef, event.target.files[0]);

    uploadTask.on("state_changed",
        (snapshot) => {
        const progress =   Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress)

        },
        (error) => {
        console.log(error);
        },
        () => {
          
    
         if(file=="mobileImg"){
            setImage(image_link+ "mobile%2F" + file+"?alt=media" );
         }else{
            setApp(image_link+ "mobile%2F" + file+"?alt=media" );
         }
         
          


        }
    );
   
    
    
}
  

    
   
  
  

    const handleMobileSubmit = async (e) => {
    e.preventDefault()
    
    try {
        console.log(app)

        await setDoc(docRef, {
            title: title,
            description: description,
            image: image,
            app: app
  
          })
        
        
        
    
    } catch (err) {
        alert(err)
    }
    }
  return (
    <div>
        <Form onSubmit={handleMobileSubmit}>
                  <Form.Group className="mb-0 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='link' value={title} onChange={event => setTitle(event.target.value)} placeholder="Title" />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control  as="textarea" name='link' value={description} onChange={event => setDescription(event.target.value)} placeholder="Description" />
                  </Form.Group>
                  <div className="d-flex">
                    <Form.Group className="mb-3 p-2 col-sm-6 h-100 flex-grow-1" >
                        <img className='w-100 mx-auto ' src={image} alt="" />
                        <Form.Label className='btn btn-primary w-100 ' htmlFor={"imageMob"}>Image</Form.Label>
                        <Form.Control type="file" name='image' id={"imageMob"}  className='d-none'  onChange={event=>handleFileUpload(event, "mobileImg")} placeholder="logoUpload" />
                    
                    </Form.Group>
                    <Form.Group className="mb-3 col-sm-6 p-2 h-100 flex-grow-1" >
                        <FontAwesomeIcon size='3x' href={app} icon={faGooglePay} />
                        <Form.Label className='btn btn-primary w-100 ' htmlFor={"appMob"}>App File</Form.Label>
                        <Form.Control type="file" name='image' id={"appMob"}  className='d-none'  onChange={event=>handleFileUpload(event, "mobileApp")} placeholder="logoUpload" />
                    
                    </Form.Group>
                  </div>

            
            
           
          
            <Button className='w-100' variant="primary" type="submit">
              Submit
            </Button>
          </Form>
    </div>
  )
}

export default MobileSection