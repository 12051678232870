import React from 'react'
import Button from 'react-bootstrap/Button';
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPicker } from 'react-fa-icon-picker'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import {db,storage,image_link} from '../firebase'
import {collection, setDoc,doc, query, orderBy, onSnapshot, Timestamp} from 'firebase/firestore'
const ServiceSection = () => {
  const docRef = doc(db, "Sections", "services" );
  const [title, setTitle]= useState("")
  const [description, setDescription]= useState("")

  const [services, setServices] = useState([
      {id:uuidv4(), icon: '', title: '', description: '' }
  ])

  useEffect(() => {
   

    const q = query(collection(db,"Sections" ))
    onSnapshot(q, (querySnapshot) => {
        setTitle(querySnapshot.docs[2].data().title)
        setDescription(querySnapshot.docs[2].data().description)
        setServices(querySnapshot.docs[2].data().services)
    })
  },[])

  

    
    const handleAccompChange = (index, name,value) => {
     
        let data = [...services];
        data[index][name] =value;
        setServices(data);
    }
  
    const addFields = () => {
    let newfield = {id:uuidv4(), icon: '', title: '', description: '' }

    setServices([...services, newfield])
    }
    const removeItem = (index,event)=>{
      
      services.splice(index,1)
      setServices([...services])

     

    }

    const handleServiceSubmit = async (e) => {
    e.preventDefault()
    
    try {

        await setDoc(docRef, {
            title: title,
            description: description,
            services: services.map((a,i)=>{
              return { id: a.id, icon: a.icon, title: a.title, description: a.description }})
  
          })
        
        
        
    
    } catch (err) {
        alert(err)
    }
    }
  return (
    <div>
        <Form onSubmit={handleServiceSubmit}>
                  <Form.Group className="mb-0 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='link' value={title} onChange={event => setTitle(event.target.value)} placeholder="Title" />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control  as="textarea" name='link' value={description} onChange={event => setDescription(event.target.value)} placeholder="Description" />
                  </Form.Group>

            
            <div className="row mx-0 align-items-center">
              <h5 className='w-50'>services</h5>
              <button className='w-50 addButt' type='button' onClick={addFields}>Add More</button>
            </div>
            {services.map((input, index) => {
              return (
                <div className='d-flex spec-flex align-items-center' key={index}>
                  
                  <Form.Group className="mb-3  ">
                    <IconPicker name="icon" value={input.icon} onChange={(v) => handleAccompChange(index, "icon",v)}  />
                  </Form.Group>
                  <div className='flex-grow-1'>
                  <Form.Group className="mb-0 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='link' value={input.title} onChange={event => handleAccompChange(index, "title", event.target.value)} placeholder="Title" />
                  </Form.Group>
                  <Form.Group className="mb-3 p-2 h-100 flex-grow-1" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control  as="textarea" name='link' value={input.description} onChange={event => handleAccompChange(index, "description", event.target.value)} placeholder="Description" />
                  </Form.Group>
                  </div>

                  <button type='button' className='removeButt' onClick={event => removeItem(index, event)} ><FontAwesomeIcon icon={faClose} /></button>
                 
                </div>
              )
            })}

           
          
            <Button className='w-100' variant="primary" type="submit">
              Submit
            </Button>
          </Form>
    </div>
  )
}

export default ServiceSection