
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from './Home';
import AdminLogin from './sections/AdminLogin';
import { AuthProvider } from './Auth';

import AdminLayout from './sections/AdminLayout';
import Dashboard from './sections/Dashboard';
import Messages from './sections/Messages';


function App() {
  

  
  
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          
        </Route>
        <Route path="login" element={sessionStorage.getItem("user")=="null" ? <AdminLogin /> : <Navigate replace to="/admin" /> }>
          
          
          </Route>
          <Route path="admin" element={  <AdminLayout/> }>
            <Route index element={<Dashboard />} />
            <Route path="messages" element={<Messages/>} />


          </Route>
        
      </Routes>
    </BrowserRouter>
  </AuthProvider>
  );
}






export default App;
